import React from "react";
import ESCertificateCard from "../homelayout/esCertificateCard";

export default function ESLazerisleme() {
  const serviceItems = [
    {
      img: "/images/lazerkesim.jpg",
      title: "Corte Por Láser",
      description: "2000×6000 Capacidad de corte por láser hasta 30 mm.",
      link: "corte-laser-plasma/corte-laser",
    },
    {
      img: "/images/plazmakesim.jpg",
      title: "Corte Por Plasma",
      description:
        "3000x12000 Capacidad de corte hasta 200 mm con corte por plasma y oxígeno.",
      link: "corte-laser-plasma/corte-plasma",
    },
  ];

  return (
    <>
      <div className="grid gap-5 grid-cols-2 lg:gap-10 lg:grid-cols-4">
        {serviceItems.map((sci, i) => (
          <ESCertificateCard key={i} sci={sci} />
        ))}
      </div>
    </>
  );
}

