import React from "react";
import { Link } from "react-router-dom";

const ESAbout = () => {
  return (
    <div className="flex flex-col lg:flex-row w-11/12 gap-10 lg:gap-0">
      <div className="flex flex-col justify-center gap-10 items-center">
        <p className="text-4xl sm:text-5xl xl:text-6xl mb-10">¿Quiénes Somos?</p>
        <p className="text-justify leading-relaxed text-sm sm:text-base lg:text-base xl:text-lg">
          Mecanizados y Ingeniería MAF S.R.L fue fundada en Ankara en el primer trimestre de 2021 por los fundadores de la marca Özenirler Hidráulico, que es una marca registrada, con la visión del empleo y basada en el compromiso con la gestión de calidad global en el campo del mecanizado. <br /><br />
          Su objetivo es reflejar los aproximadamente cuarenta y cinco años de experiencia en mecanizado, el principio de trabajo orientado al cliente y la calidad del producto de la marca Özenirler Hidráulico, que se remonta a 1973, a Mecanizado MAF y llevarlo aún más lejos. 
          Nuestra misión más importante es convertirla en una marca en la que todo individuo quiera trabajar, priorizando la calidad de maquinaria y equipos, la buena gestión del sistema y los valores humanos. <br /> <br />
          Mecanizados MAF, ISO 9001 sistema de gestión calidad y su I & D esfuerzos con un equipo de expertos del sector continúa mantener su pulso. Comenzó añadir una cadena de países europeos.
        </p>
        <div className="bg-zinc-800 text-white hover:text-black hover:bg-primary transition-all rounded-md cursor-pointer w-32 px-10 py-5 flex items-center h-9 sm:h-11 xl:h-13">
          <Link to="/es/mafmakina">
          Detalles
          </Link>
          </div>
      </div>

      <div className="hidden md:flex flex-col items-center lg:items-end gap-5">
        <img src="/images/axis.jpg" alt="img" className="flex-1 object-cover md:w-full lg:w-10/12 xl:w-11/12 " />
      </div>
    </div>
  );
};

export default ESAbout;
