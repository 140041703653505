import React from "react";
import ESCertificateCard from "../homelayout/esCertificateCard";

export default function ESCncisleme() {
  const serviceItems = [
    {
      img: "/images/freze.jpg",
      title: "Fresado CNC",
      description: "Capacidad de fresado de 3, 4 y 5 ejes.",
      link: "/mecanizado-cnc/fresado-cnc",
    },

    {
      img: "/images/torna.jpg",
      title: "Torneado CNC",
      description: "Capacidad de torneado CNC de 3 y 2 ejes desde Ø1 mm de diámetro hasta Ø2100×1700 mm de diámetro",
      link: "/mecanizado-cnc/torneado-cnc",
    },

    {
      img: "/images/taslama.jpg",
      title: "Rectificado CNC",
      description: "Rectificado de superficies tradicional.",
      link: "/mecanizado-cnc/rectificado-cnc",
    },
  ];

  return (
    <>
      <div className="grid gap-5 grid-cols-2 lg:gap-10 lg:grid-cols-4">
        {serviceItems.map((sci, i) => (
          <ESCertificateCard key={i} sci={sci} />
        ))}
      </div>
    </>
  );
}
