import React from "react";

import ENCertificateCard from "../components/homelayout/enCertificateCard";
import ENHeader from "../components/layout/enHeader";
import ENFooter from "../components/layout/enFooter";

export default function ENCatalog() {
  const serviceItems = [
    {
      img: "/machines/cmx-70-u.webp",
      title: "DMG Mori CMX 70 U",
      description:
        "5-axis CNC Vertical Milling Machine. Powerful and flexible 5-sided machining solution – on with direct measuring system in standard.",
    },
    {
      img: "/machines/dmu-50.webp",
      title: "DMG Mori DMU 50",
      description:
        "5-axis CNC Universal Milling Machine with swivel rotary table.",
    },

    {
      img: "/machines/DNM6700.webp",
      title: "Doosan DNM 6700",
      description:
        "3-axis vertical milling machine. High speed, high rigidity and suitability for universal applications.",
    },

    {
      img: "/machines/DNM-5700.webp",
      title: "Doosan DNM 5700",
      description:
        "3-axis vertical milling machine. High speed, high rigidity and suitability for universal applications.",

    },
    {
      img: "/machines/m1.png",
      title: "DMG Mori M1",
      description:
        "New 4-axis vertical milling machine.",

    },
    {
      img: "/machines/LYNX-2100LY.webp",
      title: "Doosan Lynx 2100LY",
      description:
        "4-axis CNC Lathe Machine. Lynx 2100LY series is a new model with additional Y axis on the Lynx 2100 series and enables to complete complex machining using only one setup.",

    },
    {
      img: "/machines/Goodway200.jpg",
      title: "Goodway GS-260MS",
      description:
        "2-axis CNC Lathe Machine. High speed, high rigidity and suitability for universal applications.",

    },
    {
      img: "/machines/DEA.jpg",
      title: "DEA Global S CMM",
      description:
        "The GLOBAL S Coordinate Measuring Machine (CMM) offers customized measurement efficiency based on configurations tailored to your workflow, production volume, precision, versatility, and production department capabilities.",
    },
  ];

  return (
    <div>
    <ENHeader/>
    <div className="flex flex-col items-center justify-center gap-10"/>
      <div className="flex flex-col lg:flex-row gap-10 m-20">
        <div className="flex flex-col justify-center gap-10 items-center">
          <p className="text-4xl sm:text-5xl xl:text-6xl mb-10">Our Machines</p>
          <p className="text-justify leading-relaxed text-sm lg:text-base xl:text-lg">
          At MAF Machining we use the most cutting-edge and avant-garde machinery for all our work to achieve the best possible results for all our clients.
          <br/> <br/> 
          We have 5-axis technology and the most advanced multi-area lathes in the sector that allow us to machine aluminum , steel, plastic and a wide variety of materials that we make available to our clients.
          <br/> <br/>
          To achieve this, we work only with the most innovative machinery and the most outstanding machining centers.
          </p>
        </div>
        <div className="hidden md:flex flex-col items-center lg:items-end gap-5">
          <img src="/images/axis.jpg" alt="img" className="flex-1 object-cover md:w-full lg:w-10/12 xl:w-11/12" />
        </div>
      </div>
      <div className="grid gap-5 grid-cols-1 lg:gap-10 lg:grid-cols-4 m-20 md:grid-cols-2">
        {serviceItems.map((sci, i) => (
          <ENCertificateCard key={i} sci={sci} />
          ))}
      </div>
    <ENFooter/>
    </div>
  );
}
