import React from "react";
import ENCertificateCard from "../homelayout/enCertificateCard";

export default function ENLazerisleme() {
  const serviceItems = [
    {
      img: "/images/lazerkesim.jpg",
      title: "Cnc Laser Cutting",
      description: "2000×6000 Laser cutting capability up to 30 mm.",
      link: "laser-plasma-cutting/cnc-laser-cutting",
    },
    {
      img: "/images/plazmakesim.jpg",
      title: "Cnc Plasma Cutting",
      description:
        "3000x12000 Cutting capability up to 200 mm with plasma and oxygen cutting.",
      link: "laser-plasma-cutting/cnc-plasma-cutting",
    },
  ];

  return (
    <>
      <div className="grid gap-5 grid-cols-2 lg:gap-10 lg:grid-cols-4">
        {serviceItems.map((sci, i) => (
          <ENCertificateCard key={i} sci={sci} />
        ))}
      </div>
    </>
  );
}

