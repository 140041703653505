import React from "react";
import ENCertificateCard from "../homelayout/enCertificateCard";

export default function ENCncisleme() {
  const serviceItems = [
    {
      img: "/images/freze.jpg",
      title: "CNC Milling",
      description: "3, 4 and 5 axis milling capability",
      link: "cnc-machining/cnc-milling",
    },

    {
      img: "/images/torna.jpg",
      title: "CNC Turning",
      description: " and 2 axis CNC turning capability from Ø1 mm diameter to Ø2100×Z1700 mm diameter",
      link: "cnc-machining/cnc-turning",
    },

    {
      img: "/images/taslama.jpg",
      title: "CNC Grinding",
      description: "Traditional Surface Grinding",
      link: "cnc-machining/cnc-grinding",
    },
  ];

  return (
    <>
      <div className="grid gap-5 grid-cols-2 lg:gap-10 lg:grid-cols-4">
        {serviceItems.map((sci, i) => (
          <ENCertificateCard key={i} sci={sci} />
        ))}
      </div>
    </>
  );
}
